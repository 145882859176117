import React from 'react'
import { withTranslation } from 'react-i18next'

import I18n from '../components/i18n'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Tools = ({ t }) => {
	return (
		<Layout>
			<SEO title="Home" keywords={[`agriculture`, `cultivate`, `japan`, `vietnam`, `pepper`]} />
			<div className="page">
				<div className="container grid-lg">
					<h1 className="page-title">{t('tools.title')}</h1>

          <div className="img-as-bg mb-32" style={{
            backgroundImage: `url(${require('../images/cashew_nuts_nong_cu_1.jpg')})`
          }}></div>

          <div className="img-as-bg" style={{
            backgroundImage: `url(${require('../images/cashew_nuts_nong_cu_2.jpg')})`
          }}></div>
				</div>
			</div>
		</Layout>
	)
}

export default withTranslation()(Tools)
